import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { SlimTemplate } from '../templates/SlimTemplate'
import { Nobr } from '@jfa/ui'

const Kontakt = () => {
  const {
    allFile: {
      edges: [
        {
          node: {
            childImageSharp: { fluid }
          }
        }
      ]
    }
  } = useStaticQuery(graphql`
    {
      allFile(filter: { base: { eq: "kontakt.jpg" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 460) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  return (
    <SlimTemplate>
      <Img fadeIn={false} {...{ loading: 'eager' }} fluid={fluid} />
      <p style={{ textAlign: 'center' }}>
        <Nobr>Kathrein Allenberg</Nobr> <Nobr>030 78898380</Nobr>
        <br />
        <br />
        info[at]kathreinallenberg.de
      </p>
    </SlimTemplate>
  )
}

export default Kontakt
